import dynamic from 'next/dynamic'
import compact from 'lodash/compact'
import map from 'lodash/map'
import { useMemo } from 'react'
import ErrorBoundary from '../ErrorBoundary'
import { AboutHero } from './AboutHero'
// import { AboutSections } from './AboutSections/AboutSections'
import ArticleHero from './ArticleHero'
import ArticleImageGrid from './ArticleImageGrid'
import CareerDetails from './CareerDetails'
import CareerListing from './CareerListing'
import ContactSlice from './ContactSlice'
import FeaturedProducts from './FeaturedProducts'
import HomeHero from './HomeHero'
import HomeProductCollection from './HomeProductCollection'
import ImageCarousel from './ImageCarousel'
import ImageGrid from './ImageGrid'
import ImpactSlice from './ImpactSlice'
import IngredientsSlice from './IngredientsSlice'
import MarqueeSlice from './MarqueeSlice'
import ErrorMessage from './ErrorMessage'
import VimeoPlayer from './VimeoPlayer'
import PageHero from './PageHero'
import PageLink from './PageLink'
import { Partners } from './Partners'
import ProductHero from './ProductHero'
import ProductImpactSlice from './ProductImpactSlice'
import { ProductListing } from './ProductListing/ProductListing'
import ProductMessages from './ProductMessages'
import ProductQuote from './ProductQuote'
import QuoteCarousel from './QuoteCarousel'
import RecipeSlice from './RecipeSlice'
import RelatedArticles from './RelatedArticles'
import relatedProducts from './RelatedProducts'
import RichTextSlice from './RichTextSlice'
import StoriesListing from './StoriesListing'
import { TeamListing } from './TeamListing/TeamListing'
import { ImpactHero } from './ImpactHero'
import GiftMessage from './GiftMessage'
import { PathwaysHero } from './PathwaysHero'
import { CountCarousel } from './CountCarousel'
import { StoriesCollection } from './StoriesCollection'
import { StoriesHero } from './StoriesHero'
import CallToAction from './CallToAction'
import JotformEmbed from './JotformEmbed'

// Dynamic component will not render synchronously when navigating from the client. If you do not want a jump in content on first load
// from the client do not make the component dynamic here, or create a loading/placeholder component with the same height
// const FullWidthImage = dynamic(() => import('./FullWidthImage'), { loading: () => <div style={{ paddingTop: '100%' }} /> })
// const IngredientsSlice = dynamic(() => import('./ingredientsSlice.js'), { loading: () => <div style={{ paddingTop: '100%' }} /> })
// const QuoteCarousel = dynamic(() => import('./QuoteCarousel.js'), { loading: () => <div style={{ paddingTop: '100%' }} /> })
const AboutSections = dynamic(() => import('./AboutSections/AboutSections'), {
  loading: () => <div style={{ paddingTop: '100%' }} />
})

// const Image = ({ data }) => <ResponsiveImage image={data.desktop_image} />
const sliceComponentSelector = {
  // full_width_image: Image,
  homepageHero: HomeHero,
  impactSlice: ImpactSlice,
  homeProductCollection: HomeProductCollection,
  featuredProducts: FeaturedProducts,
  quoteCarousel: QuoteCarousel,
  productListing: ProductListing,
  ingredientsSlice: IngredientsSlice,
  productQuote: ProductQuote,
  productMessages: ProductMessages,
  teamListing: TeamListing,
  richTextSlice: RichTextSlice,
  productImpact: ProductImpactSlice,
  imageGrid: ImageGrid,
  relatedProducts,
  imageCarousel: ImageCarousel,
  productHero: ProductHero,
  pageLink: PageLink,
  partners: Partners,
  pathwaysHero: PathwaysHero,
  articleHero: ArticleHero,
  articleImageGrid: ArticleImageGrid,
  recipeSlice: RecipeSlice,
  relatedArticles: RelatedArticles,
  contactSlice: ContactSlice,
  pageHero: PageHero,
  careerListing: CareerListing,
  careerDetails: CareerDetails,
  storiesListing: StoriesListing,
  aboutSections: AboutSections,
  aboutHero: AboutHero,
  marqueeSlice: MarqueeSlice,
  errorMessage: ErrorMessage,
  impactHero: ImpactHero,
  ctaHero: CallToAction,
  storiesHero: StoriesHero,
  storiesCollection: StoriesCollection,
  countCarousel: CountCarousel,
  vimeoPlayer: VimeoPlayer,
  giftMessage: GiftMessage,
  jotformEmbed: JotformEmbed
}

const Slices = ({ page, slices, settings }) => {
  const sliceComponents = useMemo(() => {
    return compact(
      map(slices, (slice, groupIndex) => {
        if (!sliceComponentSelector[slice._type]) {
          return (
            <section
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: 24
              }}
            >
              <div style={{ fontFamily: 'monospace' }}>
                The slice <strong>{slice._type}</strong> is missing
              </div>
            </section>
          )
        }

        const Component = sliceComponentSelector[slice._type]

        return (
          <ErrorBoundary key={`slice-${page._id}-${slice._key}`}>
            <Component data={slice} page={page} settings={settings} />
          </ErrorBoundary>
        )
      })
    )
  }, [slices, page])

  return sliceComponents
}

export default Slices
