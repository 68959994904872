import { useEffect, useCallback } from 'react'
import ScrollSmoother from 'gsap/dist/ScrollSmoother'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import Section from '../Section'
import createUseStyles from '../../lib/createUseStyles'
import { theme } from '../../styles/theme'

export default function JotformEmbed ({ data }) {
  const { formName, formId } = data || {}
  const classes = useStyles()

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://cdn.jotfor.ms/s/umd/latest/for-form-embed-handler.js'
    script.async = true
    script.onload = () => {
      window.jotformEmbedHandler(`iframe[id='JotFormIFrame-${formId}']`, 'https://form.jotform.com/')
    }
    document.body.appendChild(script)
  }, [])

  const refreshScrollHeight = useCallback(() => {
    const scroller = ScrollSmoother.get()
    if (scroller) {
      scroller.refresh()
      ScrollTrigger.refresh()
    }
  }, [])

  return (
    <Section>
      {formName && <h2 className={classes.title}>{formName}</h2>}
      <iframe
        id={'JotFormIFrame-' + formId}
        title='Two Good Website - Contact Info'
        onLoad={refreshScrollHeight}
        allowTransparency='true'
        allow='geolocation; microphone; camera; fullscreen'
        src={'https://form.jotform.com/' + formId}
        style={{ minWidth: '100%', maxWidth: '100%', height: '539px', border: 'none' }}
      />
    </Section>
  )
}

const useStyles = createUseStyles({
  title: {
    fontSize: 56,
    textTransform: 'uppercase',
    lineHeight: '1em',
    maxWidth: '14em',
    [theme.breakpoints.down('md')]: {
      fontSize: 32
    }
  }
})
